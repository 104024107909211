import React from "react"
import styled from 'styled-components'
import {
  Buttons, Flex,
  GreenBarSection, HeaderBanner, LightBlueSection,
  ButtonGreen,
  Text, WhiteSection, Vis, MOBILE_ONLY_CSS_WRAPPER, TABLET_ONLY_CSS_WRAPPER, DESKTOP_ONLY_CSS_WRAPPER
} from '../components/common'
import Layout from "../components/layout"
import { navTo } from '../utils/navigation'
import InfoIconSvg from '../images/Info_Blue_18.svg'
import sanitizeHtml from 'sanitize-html'
import { Breadcrumb } from "../components/Breadcrumb"
import screenListen from '../utils/screenListener'
import { useQuery } from 'react-query'
import api from '../utils/api'

const InfoIcon = styled.img`
  height:20px;
  width:20px;
  border-radius:10px;
  margin: 4px 14px 0px 0px;
`

const PreWrap = styled.pre`
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
`


const ThinHairline = styled.div`
    height: 1px;
    border-top: none;
    border-bottom: 1px solid rgba(203, 214, 225, 0.3);
    transform: scaleY(1) scaleX(0.9);
    margin: 10px 0px -5px 0px;
`

const BodyArialText = styled.span`
  padding-top: 3px;
  padding-Bottom: 3px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

const ButtonDelay = (navigateFn, payload) => {
  setTimeout(() => {
    if (payload) {
      navigateFn(payload)
    } else {
      navigateFn()
    }
  }, 300)
}

const SummaryPage = (props) => {

  let { location: { state = {} } = {} } = props
  if (state === null) state = {}
  const { bank = '[no bank provided]', checkList = {},
    durationSec, par, requestVersion = {} } = state

  const bankUri = bank === '[no bank provided]' ? null : bank.logoUri

  const keyDates = [
    [`How often will we access your data?`, `We'll access your data once`],
    [`How far back will we go?`, `We'll collect transaction data ONE month back from today`],
    [`How long will we keep your data?`, `We do not store your data. We will delete your data from our system depending on which action comes first, either when you hit the 'Finish' button or after 1 hour of inactivity with this app`],
  ]

  const supportingThirdParties = [
    `This service does not use any third parties`,
    `Your data will not be shared with anyone else`,
  ]

  const managingYourData = [
    `You can view and manage your data sharing consent using our Consumer Dashboard.`,
    `We will send you details on how to access the Consumer Dashboard via SMS once you have authorised us to connect to your bank.`
  ]


  let { isLoading: userIsLoading, data: userData = null, isSuccess: userIsSuccess } = useQuery('users', async () => {
    return new Promise((resolve, reject) => {
      api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
    })
  }, {
    retry: 1,
    refetchOnWindowFocus: false
  })

  const SummaryText = () => {
    return (<>
      <WhiteSection flex="nogrow">
        <Text.H3 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Key dates</Text.H3>
      </WhiteSection>

      {keyDates.map(([header, body], inx) => {
        const returnTxMsg = checkList['TRANSACTION_DETAILS'] === true // if user selected the transactionDetails
        if (inx === 1 && !returnTxMsg) {// How far back will we go?
          return null
        }
        return (
          <LightBlueSection key={inx} flex="col nogrow" style={{ marginBottom: '15px', padding: '14px 17px' }}>
            <Text.SummarySemiSubHeader style={{ fontWeight: '500', color: '#373737', fontFamily: 'Arial' }}>{header}</Text.SummarySemiSubHeader>
            <Flex flex="row " style={{ marginTop: '5px', minHeight: '30px', color: '#585D62' }}>
              <InfoIcon alt='' src={InfoIconSvg} />
              <BodyArialText style={{ color: '#373737', fontFamily: 'Arial' }}>{body}</BodyArialText>
            </Flex>
          </LightBlueSection>
        )
      })}

      <WhiteSection flex="nogrow" style={{ marginTop: '15px' }}>
        <Text.H3 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Third parties</Text.H3>
      </WhiteSection>
      <LightBlueSection flex="col nogrow" style={{ marginBottom: '15px', padding: '5px 17px' }}>
        {supportingThirdParties.map((body, inx) => {
          return (
            <Flex key={inx} flex="col" style={{ margin: '5px 0px' }}>
              <Flex flex="row" style={{ marginTop: '5px', minHeight: '30px', color: '#585D62' }}>
                <InfoIcon alt='' src={InfoIconSvg} />
                <BodyArialText style={{ color: '#373737', fontFamily: 'Arial' }}>{body}</BodyArialText>
              </Flex>
              {
                inx < supportingThirdParties.length - 1 &&
                <ThinHairline />
              }
            </Flex>
          )
        })}
      </LightBlueSection>

      <WhiteSection flex="nogrow" style={{ marginTop: '15px' }}>
        <Text.H3 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Managing your consent</Text.H3>
      </WhiteSection>

      <LightBlueSection flex="col nogrow" style={{ marginBottom: '15px', padding: '14px 17px' }}>
        {managingYourData.map((body, inx) => {
          return (
            <Flex key={inx} flex="col vcenter" style={{ color: '#585D62' }}>
              <Flex flex="row" style={{ marginTop: inx < managingYourData.length - 1 ? '5px' : '15px', minHeight: '30px', color: '#585D62' }}>
                <InfoIcon alt='' src={InfoIconSvg} />
                <BodyArialText style={{ color: '#373737', fontFamily: 'Arial' }}>{body}</BodyArialText>
              </Flex>
              {
                inx < managingYourData.length - 1 &&
                <ThinHairline />

              }
            </Flex>
          )
        })}

      </LightBlueSection>

    </>)
  }

  const ButtonGroup = ({ shadow = false }) => {

    const ButtonHolder = styled(Flex)`
      ${MOBILE_ONLY_CSS_WRAPPER('margin: 0px 0px')}
      ${TABLET_ONLY_CSS_WRAPPER('margin: 0px 0px')}
      ${DESKTOP_ONLY_CSS_WRAPPER('margin: 0px 39px')}
    `
    return (
      <Flex flex={`col ${shadow === true && 'shadow'}`}>
        <WhiteSection flex="col center text-center" style={{ margin: '40px 0px', padding: '0px 47px' }}>
          <Text.H3 style={{ textAlign: 'center', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Do you give us consent to collect and use your data in this way?</Text.H3>
          <Text.SemiSubHeader style={{ fontWeight: '400', textAlign: 'center', color: '#373737', fontFamily: 'Arial' }}>Once you consent, the next step is to connect with <b>{bank.brandName}</b> securely.</Text.SemiSubHeader>
        </WhiteSection>
        <ButtonHolder>
          {/*  <Buttons.BackNextButtons
            sameColor={true}
            backOnClick={() => ButtonDelay(navTo.selectyourdata_6, { ...state, bank, selectedCheckList: checkList, selectedVersion: requestVersion })}
            nextOnClick={() => ButtonDelay(navTo.connectwithyourbank_8, { ...state, bank, checkList, requestVersion })}
            nextButtonText="I consent"
          /> */}
          <ButtonGreen
            sameColor={true}
            onClick={() => ButtonDelay(navTo.selectyourdata_6, { ...state, bank, selectedCheckList: checkList, selectedVersion: requestVersion })}>
            Back
          </ButtonGreen>
          <ButtonGreen
            sameColor={true}
            style={{ margin: '0 0 0 20px'}}
            onClick={() => ButtonDelay(navTo.connectwithyourbank_8, { ...state, bank, checkList, requestVersion })}>
            I consent
          </ButtonGreen>
        </ButtonHolder>
      </Flex>
    )
  }

  const RenderDesktopButton = () => {
    const { width } = screenListen()
    let basedWidth = 520
    if (width < 1270 && width) {
      const scale = width * 0.1
      basedWidth = basedWidth - scale
    }
    return (
      <WhiteSection flex="row nogrow" style={{ marginTop: "10px", marginLeft: "18px", padding: "0px 0px", maxWidth: `${basedWidth}px` }}>
        <ButtonGroup shadow />
      </WhiteSection>
    )
  }

  return (
    <Layout.ColumnLayout title="Summary">
      <HeaderBanner logoUrl={bankUri} withStep={true} hamburger={false} commercial={userIsSuccess} commercialProperties={userData} userIsLoading={userIsLoading}>
        <Breadcrumb from={3} to={6} />
      </HeaderBanner>
      {/* mobile only */}
      <Vis.MobileOnly flex="col">
        <WhiteSection flex="col" style={{ margin: '11px 10px 0px 10px' }}>
          <Vis.MobileOnly><Text.Steps>Step 3 of 6</Text.Steps></Vis.MobileOnly>
          <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Summary</Text.H1>
          <GreenBarSection style={{ marginBottom: '21px', color: '#373737', fontFamily: 'Arial' }}>Here's what we are going to do.  <br /><br /> Please confirm you understand and agree for us to collect your data by selecting <b>&quot;I consent&quot;</b></GreenBarSection>
        </WhiteSection>
        <SummaryText />
        <WhiteSection flex="row" style={{ margin: '20px 0px' }}>
          <ButtonGroup />
        </WhiteSection>
      </Vis.MobileOnly>

      {/* Tablet */}

      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Summary</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Here's what we are going to do.  <br /><br /> Please confirm you understand and agree for us to collect your data by selecting <b>&quot;I consent&quot;</b></GreenBarSection>
        </WhiteSection>

        <Flex flex="col" style={{ margin: '0px 40px', padding: '0px 20px' }}>
          <SummaryText />
        </Flex>
        <WhiteSection flex="row" style={{ margin: '10px 100px' }}>
          <ButtonGroup />
        </WhiteSection>
      </Vis.TabletOnly>


      {/*  Desktop */}

      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Summary</Text.H1>
              <GreenBarSection style={{ maxWidth: '430px', color: '#373737', fontFamily: 'Arial' }}>Here's what we are going to do.  <br /><br /> Please confirm you understand and agree for us to collect your data by selecting <b>&quot;I consent&quot;</b></GreenBarSection>
            </WhiteSection>
            <RenderDesktopButton />
          </WhiteSection>
          <Flex flex="w50 col" style={{ paddingTop: '35px', marginRight: '50px' }}>
            <Flex flex="col">
              <SummaryText />
            </Flex>
          </Flex>
        </WhiteSection>
        {/* {
          isCommercial &&
          <WhiteSection flex="row nogrow highlight" style={{ margin: '0px 50px 0px 100px', maxWidth: '1000px' }}>
            <PreWrap style={{ padding: '10px 10px', backgroundColor: 'lightgrey', maxWidth: '980px' }}>
              Duration: {durationSec}, Pushed Authroisation Request:{JSON.stringify(par)}
              {`\n`}Bank: {JSON.stringify(bank.brandName)}
              {`\n`}Checklist: {JSON.stringify(checkList)}</PreWrap>
          </WhiteSection>
        } */}
      </Vis.DesktopOnly>
    </Layout.ColumnLayout>
  )
}

export default SummaryPage
